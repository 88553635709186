import React, { SetStateAction, useState } from 'react'
import { Form, Row } from 'react-bootstrap'
import { ILocation } from '../../../../shared/interfaces/ICleaning'
import { LocationService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import LocationForm from './LocationForm';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function LocationCreate() {
    const [currentLocation, setCurrentLocation] = useState<Partial<ILocation>>({});
    const navigate = useNavigate();
    const { t } = useTranslation();

    const submitHandler = () => {
        LocationService.createLocation(currentLocation).then((response) => {
            if (response?.id) {
                // setCurrentLocation(response);
                toast.success(t('general.successfullyCreated'))
                navigate('/admin/location-list');
            }
        }).catch(err => handleError(err));
    }

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
        }}>
            <Row className={'justify-content-center'}>
                <LocationForm currentLocation={currentLocation as ILocation}
                    setCurrentLocation={setCurrentLocation as React.Dispatch<SetStateAction<ILocation>>} />
            </Row>
        </Form>
    )
}

