import React, { SetStateAction, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap';
import GlobalCard from '../../../../shared/components/GlobalCard';
import { useNavigate, useParams } from 'react-router-dom';
import { ICleaning, ICreateCleaning } from '../../../../shared/interfaces/ICleaning';
import { UserCleaningService } from '../../create-cleaning/service';
import { handleError } from '../../../../shared/functions/handleError';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { IUser } from '../../../../shared/interfaces/IUser';
import CreateCleaningForm from '../../create-cleaning/components/CreateCleaningForm';
import { multipartFormData } from '../../../../utils/api/api.service';
import { USERS_URL } from '../../../../utils/api/endpoints';
import { toast } from 'react-toastify';
import Fancybox from '../../../../shared/components/Fancybox';
import ImageHandler from '../../../../shared/components/ImageHandler';
import { IPhoto } from '../../../../shared/interfaces/IPhoto';
import { generatePhotoUrl } from '../../../../shared/functions/generatePhotoUrl';
import { MdDelete } from 'react-icons/md';
import { EStatus } from '../../../../shared/enums/EStatus';
import DeleteConfirmationModal from '../../../../shared/components/DeleteConfirmationModal';



export default function LocationSingleCleaningUpdate() {
    const { id: cleaningId } = useParams();
    const [currentCleaning, setCurrentCleaning] = useState<ICreateCleaning | null>(null);
    const { t } = useTranslation();
    const { id: userId } = useSelector((state: RootState) => state.auth.userData) as IUser;
    const [files, setFiles] = useState<FileList | null>(null);
    const [selectedImage, setSelectedImage] = useState<IPhoto | null>(null);
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        setIsLoading(true);
        multipartFormData(files ? files : [], `${USERS_URL}/${userId}/cleanings/${cleaningId}`, currentCleaning, ['street', 'locationId'], 'put', 'file', true).then(response => {
            if (response) {
                toast.success(t("general.successfullyUpdated"));
                navigate(-1);
                setCurrentCleaning(null);
            }
        }).catch(err => handleError(err, t)).finally(() => {
            setIsLoading(false);
        })
    }

    const handleCloseDeleteModal = () => {
        setIsDeleteVisible(false);
        setSelectedImage(null);
    }

    const handleDeleteImage = () => {
        !!userId && !!cleaningId && selectedImage?.id && UserCleaningService
            .deleteCleaningImageById(userId, Number(cleaningId), selectedImage?.id)
            .then(() => {
                // @ts-ignore
                setCurrentCleaning(prev => ({ ...prev, photos: prev?.photos?.filter(img => img.id !== selectedImage?.id) }))
                toast.success(t('general.successfullyDeleted'));
                setSelectedImage(null);
                setIsDeleteVisible(false);
            }).catch(err => handleError(err))
    }

    useEffect(() => {
        !!userId && !!cleaningId && UserCleaningService.getCleaningById(userId, +cleaningId)
            .then(response => {
                setCurrentCleaning(response);
            }).catch(err => handleError(err))
    }, [userId, cleaningId])

    return (
        <>
            <Row className="justify-content-center">
                <Col md={8} className={'px-0'}>
                    <GlobalCard>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >

                            <Fancybox
                                options={{
                                    Carousel: {
                                        infinite: false,
                                    },
                                }}
                            >
                                <Row>
                                    {(currentCleaning as unknown as ICleaning)?.photos?.map((photo, index) => {
                                        return (
                                            <Col md={3} key={index} className={'mb-3 position-relative'}>
                                                <a data-fancybox="gallery" href={generatePhotoUrl(photo?.url)}>
                                                    {currentCleaning?.status === EStatus.PENDING && <div
                                                        onClick={() => {
                                                            setSelectedImage(photo);
                                                            setIsDeleteVisible(true);
                                                        }}
                                                        className='top-right-icon bg-white hover-effect card-border-radius ' style={{
                                                            top: 10,
                                                            right: 25
                                                        }}>
                                                        <MdDelete className='text-danger'
                                                            style={{
                                                                width: "20px",
                                                                height: "20px"
                                                            }} />
                                                    </div>}
                                                    <ImageHandler key={index} className={'w-100 h-150px img-cover card-border-radius cursor-pointer'}
                                                        src={generatePhotoUrl(photo?.url)} />
                                                </a>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Fancybox>

                            <Row className={'mt-2'}>
                                <CreateCleaningForm currentCleaning={currentCleaning as ICreateCleaning}
                                    setCurrentCleaning={setCurrentCleaning as React.Dispatch<SetStateAction<ICreateCleaning>>}
                                    files={files}
                                    setFiles={setFiles}
                                    isLoading={isLoading}
                                />
                            </Row>
                        </Form>
                    </GlobalCard>
                </Col>
            </Row>

            <DeleteConfirmationModal

                handleConfirm={handleDeleteImage}
                handleClose={handleCloseDeleteModal} show={isDeleteVisible} title={`image ID: ${selectedImage?.id}`} />

        </>
    )
}

