import React, {useEffect, useState} from 'react';
import {LocationService} from '../../admin/location-list/service';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {usePagination} from '../../../shared/hooks/usePagination';
import {handleError} from '../../../shared/functions/handleError';
import {ILocation} from '../../../shared/interfaces/ICleaning';
import {Col, Row} from 'react-bootstrap';
import {IPagination} from '../../../shared/interfaces/IPagination';
import InfinityLoader from '../../../shared/components/InfiniteLoader';
import CleaningFilter from '../../admin/location-cleaning-list/components/CleaningFilter';
import CleaningCard from '../../admin/location-cleaning-list/components/CleaningCard';

const LocationCleaningUserList = () => {

    const [locations, setLocations] = useState<ILocation[]>([]);
    const {pagination, changeFilterHandler, count, setCount, setPagination} = usePagination({perPage: 24});
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        LocationService.getAllLocations(pagination).then(response => {
            setLocations(response?.data);
            setCount(response?.meta?.total);
        }).catch(err => handleError(err?.response?.data?.message)
        )
    }, [pagination, setCount])

    return (

        <div className="row mt-4">
            <CleaningFilter changeFilterHandler={changeFilterHandler} showNameFilter/>

            <div className="col-12 px-0">
                <InfinityLoader dataLength={locations?.length}
                                hasMore={count > pagination?.perPage}
                                showSeenAll={pagination?.perPage > 24}
                                fetchData={() => setPagination((prev: IPagination) => ({
                                    ...prev,
                                    perPage: prev?.perPage + 10
                                }))}>
                    <Row>
                        {
                            !!locations.length ?
                                locations?.map((location) => {
                                    return (

                                        <div className="col-md-4 ps-0 cursor-pointer hover-effect"
                                             onClick={() => navigate(`/user/cleaning/${location?.id}?locationName=${location?.name}`)}
                                             key={location?.id}
                                        >
                                            <CleaningCard location={location}/>
                                        </div>
                                    )
                                }) :

                                <Col md={12} className={'px-0 mt-3'}>
                                    <h6 className="mb-0"> {t('general.listEmpty')}</h6>
                                </Col>
                        }
                    </Row>
                </InfinityLoader>
            </div>
        </div>

    )
};

export default LocationCleaningUserList;
