import React, { useEffect } from 'react';
import './App.scss';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './authentification/login';
import Layout from './shared/components/Layout';
import { toast } from 'react-toastify';
import { AuthService } from './authentification/login/service';
import authSlice from './store/slices/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import { routeList } from './utils/routes';
import { handleError } from './shared/functions/handleError';
import { useTranslation } from 'react-i18next';

function App() {
    const token = JSON.parse(localStorage.getItem('token') as string);
    const dispatch = useDispatch();
    const { isAuthenticated, userData } = useSelector((state: RootState) => state.auth);
    const currentRoleRoutes = routeList?.find(route => route.role === userData?.role);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (token) {
            AuthService.me().then((response) => {
                if (response?.deleted) {
                    localStorage.clear();
                    dispatch(authSlice.actions.setLogoutState())
                    navigate('/');
                    toast.error(t("error.userDeleted"));
                }
                else {
                    dispatch(authSlice.actions.setLoginState({
                        isAuthenticated: true,
                        userData: response
                    }));
                }
            }
            ).catch((err) => {
                handleError(err);
            })
        }
    }, [token, dispatch, navigate, t]);

    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />
            </Routes>

            {isAuthenticated && <Layout>
                <Routes>
                    {currentRoleRoutes?.routes?.map(route => {
                        return (
                            <Route path={route?.path} element={route?.component} key={route.path} />
                        )
                    })}
                </Routes>
            </Layout>}
        </>
    );
}

export default App;
