import { IBaseModel } from "../../../shared/interfaces/IBaseModel";
import { ICleaning, ICreateCleaning } from "../../../shared/interfaces/ICleaning";
import { ICleaningPagination } from "../../../shared/interfaces/ICleaningPagination";
import { requests } from "../../../utils/api/api.service";
import { USERS_URL } from "../../../utils/api/endpoints";

export const UserCleaningService = {
    createCleaning: (userId: number, payload: ICreateCleaning) =>
        requests.post(`${USERS_URL}/${userId}/cleanings`, payload),

    getCleanings: (userId: number, pagination: ICleaningPagination): Promise<IBaseModel<ICleaning[]>> =>
        requests.get(`${USERS_URL}/${userId}/cleanings`, pagination),

    getCleaningById: (userId: number, cleaningId: number): Promise<ICleaning> =>
        requests.get(`${USERS_URL}/${userId}/cleanings/${cleaningId}`),

    deleteCleaningImageById: (userId: number, cleaningId: number, imageId: number) => requests.delete(`${USERS_URL}/${userId}/cleanings/${cleaningId}/photos/${imageId}`)
};

