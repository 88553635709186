import {IBaseModel} from '../../../shared/interfaces/IBaseModel';
import {ICleaning} from '../../../shared/interfaces/ICleaning';
import {IPagination} from '../../../shared/interfaces/IPagination';
import {requests} from '../../../utils/api/api.service';
import {USERS_URL} from '../../../utils/api/endpoints';

export const ClientCleaningService = {
    getAllCleaning: (pagination: IPagination, userId: number): Promise<IBaseModel<ICleaning[]>> =>
        requests.get(`${USERS_URL}/${userId}/cleanings`, pagination),
    getCleaningById: (cleaningId: string, userId: number): Promise<ICleaning> =>
        requests.get(`${USERS_URL}/${userId}/cleanings/${cleaningId}`),
}