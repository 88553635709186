import React from 'react'
import {useTranslation} from 'react-i18next';
import {EStatus} from '../../../../shared/enums/EStatus';

interface IProps {
    changeFilterHandler: (name: string, value: string) => void;
    showNameFilter?: boolean;
    showStatusFilter?: boolean;
}

export default function CleaningFilter({
                                           changeFilterHandler,
                                           showStatusFilter = false,
                                           showNameFilter = false
                                       }: IProps) {
    const {t} = useTranslation();
    return (
        <div className="col-12 px-0 mb-3 d-flex align-items-center justify-content-between">
            {showNameFilter &&
                <input type="text" className="form-control"
                       placeholder={t('general.typeSomething')}
                       onChange={(e) => changeFilterHandler(e.target.name, e.target.value)}
                       name="name"/>
            }
            {showStatusFilter &&
                <div className={'d-flex justify-content-end w-100'}>
                    <select name="status" className="form-select w-25"
                            onChange={(e) => {
                                changeFilterHandler('status', e.target.value)
                            }}
                    >
                        <option hidden>
                            {t('general.filterByStatus')}
                        </option>
                        {Object.keys(EStatus)?.map(status => {
                            return (
                                <option value={status} key={status}>
                                    {status}
                                </option>
                            )
                        })}
                        <option value="">All</option>
                    </select>
                </div>
            }
        </div>
    )
}
