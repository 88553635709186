import React, {SetStateAction, useEffect, useState} from 'react'
import {ILocation} from '../../../../shared/interfaces/ICleaning';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {LocationService} from '../service';
import {handleError} from '../../../../shared/functions/handleError';
import {Form, Row} from 'react-bootstrap';
import LocationForm from './LocationForm';
import {toast} from 'react-toastify';

export default function LocationUpdate() {
    // const [currentLocation, setCurrentLocation] = useState<Partial<ILocation>>({});
    const [currentLocation, setCurrentLocation] = useState<ILocation | null>(null);
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        !!id && LocationService.getLocationById(+id)
            .then(response => setCurrentLocation(response)).catch(err => handleError(err))
    }, [id]);

    const submitHandler = () => {
        !!currentLocation?.id && LocationService.updateLocation({
            name: currentLocation?.name,
            id: currentLocation?.id
        }).then(async () => {
            await LocationService.adminGenerateQR(currentLocation?.id)
            toast.success(t('general.successfullyUpdated'));
            navigate('/admin/location-list');
        }).catch(err => handleError(err))
    }

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler();
        }}>
            <Row className={'justify-content-center'}>
                {!!currentLocation?.id && <LocationForm currentLocation={currentLocation}
                                                        setCurrentLocation={setCurrentLocation as React.Dispatch<SetStateAction<ILocation>>}/>}
            </Row>
        </Form>
    )
}
