import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth.slice';

export class StoreKeeper {
    public static store: any;
}

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
