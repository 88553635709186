import React, { SetStateAction, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleError } from '../../../../shared/functions/handleError';
import { IUser } from '../../../../shared/interfaces/IUser';
import { UserService } from '../service';
import UserForm from './UserForm';

const UserUpdate = () => {
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const submitHandler = () => {
        !!currentUser?.id && UserService.updateUser({
            email: currentUser?.email,
            lastName: currentUser?.lastName,
            firstName: currentUser?.firstName,
            password: currentUser?.password,
            role: currentUser?.role,
            id: currentUser?.id,
            canEditTime: currentUser?.canEditTime,
        })
            .then(response => {
                if (response?.id) {
                    toast.success(t('general.successfullyUpdated'))
                    navigate('/admin/user-list')
                }
            }).catch(err => handleError(err))
    }

    useEffect(() => {
        !!id && UserService.getUserById(+id)
            .then(response => {
                setCurrentUser(response);
            }).catch(err => handleError(err))
    }, [id])

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler()
        }}>
            <Row className={'justify-content-center'}>
                {!!currentUser?.id && <UserForm currentUser={currentUser}
                    setCurrentUser={setCurrentUser as React.Dispatch<SetStateAction<IUser>>} />}
            </Row>
        </Form>
    );
};

export default UserUpdate;
