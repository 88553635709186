import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import InfinityLoader from '../../../shared/components/InfiniteLoader';
import {handleError} from '../../../shared/functions/handleError';
import {usePagination} from '../../../shared/hooks/usePagination';
import {ICleaning} from '../../../shared/interfaces/ICleaning';
import {IPagination} from '../../../shared/interfaces/IPagination';
import {IUser} from '../../../shared/interfaces/IUser';
import {RootState} from '../../../store/store';
import SingleCleaningCard from '../../admin/cleaning-list/components/SingleCleaningCard';
import CleaningFilter from '../../admin/location-cleaning-list/components/CleaningFilter';
import {ClientCleaningService} from './service';

const CleaningClientList = () => {
    const {id} = useSelector((state: RootState) => state.auth.userData) as IUser;
    const [cleaningList, setCleaningList] = useState<ICleaning[]>([]);
    const {t} = useTranslation();
    const {pagination, changeFilterHandler, count, setCount, setPagination} = usePagination({perPage: 12})
    useEffect(() => {
        !!id && ClientCleaningService.getAllCleaning(pagination, id)
            .then(response => {
                setCleaningList(response?.data);
                setCount(response?.meta?.total);
            }).catch(err => handleError(err));
    }, [setCount, pagination, id])
    return (
        <div className="row mt-4">
            <CleaningFilter changeFilterHandler={changeFilterHandler} showStatusFilter/>
            <div className="col-12 px-0">
                <InfinityLoader dataLength={cleaningList?.length}
                                hasMore={count > pagination?.perPage}
                                showSeenAll={pagination?.perPage > 24}
                                fetchData={() => setPagination((prev: IPagination) => ({
                                    ...prev,
                                    perPage: prev?.perPage + 10
                                }))}>
                    <Row>
                        {
                            !!cleaningList.length ?
                                cleaningList?.map((cleaning) => {
                                    return (

                                        <Col md={3} className={'ps-0 mb-3'} key={cleaning?.id}>
                                            <SingleCleaningCard cleaning={cleaning}
                                                                changeFilterHandler={changeFilterHandler}/>
                                        </Col>
                                    )
                                }) :
                                <Col md={12} className={'px-0 mt-3'}>
                                    <h6 className="mb-0"> {t('general.listEmpty')}</h6>
                                </Col>
                        }
                    </Row>
                </InfinityLoader>
            </div>
        </div>
    );
};

export default CleaningClientList;
