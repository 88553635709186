import React from 'react'
import { Button } from 'react-bootstrap'
import i18n, { resources } from '../../utils/localization/i18n'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import authSlice from '../../store/slices/auth.slice';
import { RootState } from '../../store/store';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentUser = useSelector((state: RootState) => state.auth);
    return (
        <div className="bg-white py-3 position-fixed top-0 w-100 border-bottom z-3">
            <div className="container d-flex justify-content-between align-items-center">
                <h5 className="mb-0">
                    {t('general.welcomeBack') + ', ' + currentUser?.userData?.displayName}
                </h5>
                <div className="d-flex align-items-center">
                    <select className="form-select"
                        value={i18n.language}
                        onChange={(e) => {
                            i18n.changeLanguage(e?.target?.value);
                            localStorage.setItem('language', e?.target?.value)
                        }}>
                        {Object.keys(resources)?.map(language => {
                            return (
                                <option value={language} key={language}>{t(`language.${language}`)}</option>
                            )
                        })}
                    </select>

                    <Button variant="outline-primary" className="ms-2 w-100" onClick={() => {
                        localStorage.clear();
                        navigate('/');
                        dispatch(authSlice.actions.setLogoutState())
                    }}>
                        {t('buttons.logout')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
