import React, {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {handleError} from '../../../../shared/functions/handleError';
import {ILocation} from '../../../../shared/interfaces/ILocation';
import {LocationService} from '../../location-list/service';

interface IProps {
    pagination: any;
    changeFilterHandler?: ((name: string, value: (string | number | boolean | null)) => void) | undefined
}

const TimeregFilter = ({changeFilterHandler, pagination}: IProps) => {
    const [locations, setLocations] = useState<ILocation[]>([]);
    const {t} = useTranslation();
    useEffect(() => {
        LocationService.getAllLocations({page: 1, perPage: 9999}).then(response => {
            setLocations(response?.data)
        }).catch(err => handleError(err))
    }, []);
    return (
        <>
            <Col md={12} className={'ps-0'}>
                <label htmlFor="fromDate" className="form-label">
                    {t('general.location')}
                </label>
                <select name="locationId"
                        onChange={(e) => {
                            changeFilterHandler && changeFilterHandler('locationId', +e.target.value)
                        }}
                        className="form-select">
                    {locations?.map(location => {
                        return (
                            <option value={location?.id} key={location?.id}>
                                {location?.name}
                            </option>
                        )
                    })}
                    <option value="">
                        All
                    </option>
                </select>

            </Col>
            <Col md={6} className={'ps-0 mb-3 mt-2'}>
                <label htmlFor="fromDate" className="form-label">
                    {t('general.fromDate')}
                </label>
                <input type="date"
                       id={'fromDate'}
                       onChange={(event) => {
                           const {value} = event.target;
                           if (changeFilterHandler) {
                               changeFilterHandler('fromDate', value)
                           }
                       }}
                       value={pagination?.fromDate}
                       className="form-control"/>
            </Col>
            <Col md={6} className={'ps-0 mb-3 mt-2'}>
                <label htmlFor="fromDate" className="form-label">
                    {t('general.toDate')}
                </label>
                <input type="date"
                       id={'toDate'}
                       onChange={(event) => {
                           const {value} = event.target;
                           if (changeFilterHandler) {
                               changeFilterHandler('toDate', value)
                           }
                       }}
                       defaultValue={pagination?.toDate}
                       className="form-control"/>
            </Col>
        </>
    );
};

export default TimeregFilter;
