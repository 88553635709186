import React, {useEffect, useState} from 'react'
import {ILocation} from '../../../shared/interfaces/ICleaning';
import {handleError} from '../../../shared/functions/handleError';
import {usePagination} from '../../../shared/hooks/usePagination';
import LocationCard from './components/LocationCard';
import {LocationService} from './service';
import {Col, Row} from 'react-bootstrap';
import LocationsFilter from './components/LocationsFilter';
import {useTranslation} from 'react-i18next';
import {IPagination} from '../../../shared/interfaces/IPagination';
import InfinityLoader from '../../../shared/components/InfiniteLoader';

export default function LocationList() {
    const [locationList, setLocationList] = useState<ILocation[]>([]);
    const {pagination, count, setCount, changeFilterHandler, setPagination} = usePagination(
        {perPage: 24}
    );
    const {t} = useTranslation();

    useEffect(() => {
        LocationService.getAllLocations(pagination)
            .then(response => {
                setLocationList(response?.data);
                setCount(response?.meta?.total);
            }).catch(err => handleError(err?.response?.data?.message)
        )
    }, [pagination, setCount])
    return (
        <>
            <Row>
                <LocationsFilter count={count} changeFilterHandler={changeFilterHandler}/>
            </Row>

            <InfinityLoader dataLength={locationList?.length}
                            hasMore={count > pagination?.perPage}
                            showSeenAll={pagination?.perPage > 24}
                            fetchData={() => setPagination((prev: IPagination) => ({
                                ...prev,
                                perPage: prev?.perPage + 10
                            }))}>
                <Row>
                    {
                        !!locationList.length ?
                            locationList?.map((location) => {
                                return (<LocationCard location={location} changeFilterHandler={changeFilterHandler}
                                                      key={location.id}/>)
                            }) :

                            <Col md={12} className={'px-0 mt-3'}>
                                <h6 className="mb-0"> {t('general.listEmpty')}</h6>
                            </Col>
                    }
                </Row>
            </InfinityLoader>


        </>
    )
}
