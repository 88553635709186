import React, { SetStateAction } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ImageHandler from './ImageHandler';
import Fancybox from './Fancybox';


interface IProp {
    files: FileList | null;
    setFiles: React.Dispatch<SetStateAction<FileList | null>>;
    isMultiple: boolean;
    isRequired: boolean;
    formatType: string;
}

export default function FileUpload({ setFiles, files, isMultiple = false, isRequired = false, formatType = "image/*" }: IProp) {
    const { t } = useTranslation();
    return (
        <>
            <Fancybox
                options={{
                    Carousel: {
                        infinite: false,
                    },
                }}
            >
                <Row>
                    {files && [...files]?.map((file, index) => {
                        return (
                            <Col md={3} key={index} className={'mb-3'}>
                                <a data-fancybox="gallery" href={URL.createObjectURL(file)}>
                                    <ImageHandler key={index} className={'w-100 h-150px img-cover card-border-radius cursor-pointer'}
                                        src={URL.createObjectURL(file)} />
                                </a>
                            </Col>
                        )
                    })}
                </Row>
            </Fancybox>

            <label htmlFor='fileUpload' className='w-100 position-relative cursor-pointer'>
                <input type="file"
                    id='fileUpload'
                    name="file"
                    minLength={5}
                    multiple={isMultiple}
                    required={isRequired}
                    onChange={(e) => setFiles(e.target.files)}
                    accept={formatType}
                    className='position-absolute w-100 cursor-pointer'
                    style={{ opacity: 0, top: 5 }}
                />
                <Button variant="outline-primary" className="w-100">{(t("buttons.clickToUpload"))}</Button>
            </label>
        </>
    )
}
