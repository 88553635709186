import React from 'react';
import { Badge } from 'react-bootstrap';
import { EStatus } from '../enums/EStatus';

interface IProps {
    status: EStatus
}

const getBadgeColor = (currentStatus: EStatus) => {
    if (currentStatus === EStatus.PENDING) return 'warning'
    if (currentStatus === EStatus.REJECTED) return 'danger'
    if (currentStatus === EStatus.COMPLETED) return 'success'
}
const StatusBadge = ({ status }: IProps) => {

    return (
        <Badge className={`bg-${getBadgeColor(status)}`}>
            {status}
        </Badge>
    );
};

export default StatusBadge;
