import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BiStreetView, BiUser } from 'react-icons/bi';
import { CgLock } from 'react-icons/cg';
import { GoLocation } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StatusBadge from '../../../../shared/components/StatusBadge';
import { formatDate } from '../../../../shared/functions/formatDate';
import { generatePhotoUrl } from '../../../../shared/functions/generatePhotoUrl';
import { ICleaning } from '../../../../shared/interfaces/ICleaning';
import { EStatus } from '../../../../shared/enums/EStatus';
import DeleteConfirmationModal from '../../../../shared/components/DeleteConfirmationModal';
import { IUser } from '../../../../shared/interfaces/IUser';
import { RootState } from '../../../../store/store';
import { CleaningService } from '../../location-cleaning-list/service';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { ERole } from '../../../../shared/enums/ERole';
import { FaRegCircleUser } from "react-icons/fa6";


interface IProps {
    changeFilterHandler: (name: string, value: string) => void;
    cleaning: ICleaning;
}

const SingleCleaningCard = ({ cleaning, changeFilterHandler }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { role } = useSelector((state: RootState) => state.auth.userData) as IUser;
    const { user, photos, status, location, street, createdAt, id } = cleaning;

    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const handleClose = () => setIsDeleteModalOpened(false);
    const handleShow = () => setIsDeleteModalOpened(true);

    const handleDeleteCleaning = async () => {
        await CleaningService.deleteCleaningById(cleaning?.id).then(() => {
            changeFilterHandler('updatedAt', `${new Date().getTime()}`)
            toast.success(t('general.successfullyDeleted'));
        }).catch(err => handleError(err))
    }

    return (
        <>
            <Card>
                <Card.Img src={generatePhotoUrl(photos?.[0]?.url)} className={'h-150px img-cover'} />
                <Card.Body>
                    <Row>
                        <Col md={12} className={'px-0'}>
                            <StatusBadge status={status} />
                            <div className="d-flex flex-column w-100 mt-2">
                                <div className={'d-flex align-items-center my-1'}>
                                    <BiUser className={'text-muted'} />
                                    <h6 className="ms-1 mb-0 text-muted">
                                        {user?.firstName} {user?.lastName}
                                    </h6>
                                </div>
                                <div className={'w-100 d-flex align-items-center my-1'}>
                                    <GoLocation className={'text-muted'} />
                                    <h6 className="ms-1 mb-0 text-muted">
                                        {location?.name}
                                    </h6>
                                </div>
                                <div className={'w-100 d-flex align-items-center my-1'}>
                                    <FaRegCircleUser className={'text-muted'} />
                                    <h6 className="ms-1 mb-0 text-muted">
                                        {!!cleaning?.client?.id ? `${cleaning?.client?.firstName} ${cleaning?.client?.lastName}` : '-'}
                                    </h6>
                                </div>
                                <div className={'w-100 d-flex align-items-center my-1'}>
                                    <BiStreetView className={'text-muted'} />
                                    <h6 className="ms-1 mb-0 text-muted">
                                        {street}
                                    </h6>
                                </div>
                                <div className={'w-100 d-flex align-items-center my-1'}>
                                    <CgLock className={'text-muted'} />
                                    <h6 className="mb-0 text-muted ms-1">
                                        {formatDate(createdAt)}
                                    </h6>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className={'d-flex align-items-center justify-content-between'}>
                    <h6 className="mb-0 text-muted">
                        ID #{id}
                    </h6>
                    <div className={'d-flex align-items-center'}>
                        {role === ERole.ADMIN &&
                            cleaning?.status === EStatus.PENDING &&
                            <Button size={'sm'} variant={'outline-danger'} onClick={handleShow}>
                                {t('buttons.delete')}
                            </Button>
                        }
                        <Button size={'sm'}
                            onClick={() => navigate(`/${role?.toLowerCase()}/cleaning/update/${id}`)}
                            variant={'primary'} className={'ms-2'}>
                            {t('buttons.open')}
                        </Button>
                    </div>
                </Card.Footer>
            </Card>

            <DeleteConfirmationModal show={isDeleteModalOpened} handleClose={handleClose}
                handleConfirm={handleDeleteCleaning}
                title={String(cleaning?.id)} />
        </>
    );
};

export default SingleCleaningCard;
