
interface IImageHandlerProps {
    src: string;
    className?: string;
    errorPath?: string;
}

const ImageHandler = ({
    src,
    className,
    errorPath = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOwRConBYl2t6L8QMOAQqa5FDmPB_bg7EnGA&s',
}: IImageHandlerProps) => {
    return (
        <img
            src={src ? src : errorPath}
            className={className}
            alt={src}
            onError={(e: any) => (e.target.onerror = null) && (e.target.src = errorPath)}
        />
    );
};

export default ImageHandler;
