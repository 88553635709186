import {CiCircleList} from 'react-icons/ci';
import CleaningClientList from '../../../app/client/cleaning-list';
import CleaningClientUpdate from '../../../app/client/cleaning-list/components/CleaningClientUpdate';
import NotFound from '../../../authentification/404';

export const clientRoutes = [
    {
        path: '/client/cleaning-list',
        showInFooter: true,
        icon: <CiCircleList/>,
        title: 'user.cleaningList',
        component: <CleaningClientList/>
    },
    {
        path: '/client/cleaning/update/:id',
        component: <CleaningClientUpdate/>
    },
    {
        path: '*',
        component: <NotFound/>
    }
]