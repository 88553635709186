import { BiPencil } from 'react-icons/bi';
import { MdCleaningServices } from 'react-icons/md';
import UserCreateCleaning from '../../../app/user/create-cleaning';
import LocationCleaningUserList from '../../../app/user/location-cleaning-list';
import NotFound from '../../../authentification/404';
import CreateCleaning from '../../../app/user/create-cleaning/components/CreateCleaning';
import LocationCleaningCards from '../../../app/user/location-cleaning-list/components/LocationCleaningCards';
import LocationSingleCleaningUpdate from '../../../app/user/location-cleaning-list/components/LocationSingleCleaningUpdate';

export const userRoutes = [
    {
        path: '/user/cleaning/create',
        showInFooter: true,
        icon: <BiPencil />,
        title: 'user.createCleaning',
        component: <UserCreateCleaning />
    },
    {
        path: '/user/location-cleaning-list',
        showInFooter: true,
        icon: <MdCleaningServices />,
        title: 'user.myCleanings',
        component: <LocationCleaningUserList />
    },
    {
        path: '/user/cleaning/create/form',
        component: <CreateCleaning />
    },
    {
        path: '/user/cleaning/:id',
        component: <LocationCleaningCards />
    },
    {
        path: '/user/cleaning/update/:id',
        component: <LocationSingleCleaningUpdate />
    },
    {
        path: '*',
        component: <NotFound />
    }
]