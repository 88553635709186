import { IBaseModel } from '../../../shared/interfaces/IBaseModel';
import { ICleaning } from '../../../shared/interfaces/ICleaning';
import { IPagination } from '../../../shared/interfaces/IPagination';
import { requests } from '../../../utils/api/api.service';
import { ADMIN_CLEANING_URL } from '../../../utils/api/endpoints';
import { IUpdateCleaningState } from '../cleaning-list/components/SingleCleaningForm';

export const CleaningService = {
    getSingleCleaning: (pagination: IPagination): Promise<IBaseModel<ICleaning[]>> =>
        requests.get(ADMIN_CLEANING_URL, pagination),

    getCleaningById: (cleaningId: string): Promise<ICleaning> =>
        requests.get(`${ADMIN_CLEANING_URL}/${cleaningId}`),

    updateCleaning: (payload: IUpdateCleaningState, cleaningId: number) =>
        requests.put(`${ADMIN_CLEANING_URL}/${cleaningId}`, payload),

    downloadAllImages: (cleaningId: number) =>
        requests.get(`${ADMIN_CLEANING_URL}/${cleaningId}/getAllImages`),

    deleteCleaningById: (cleaningId: number) =>
        requests.delete(`${ADMIN_CLEANING_URL}/${cleaningId}`)
}