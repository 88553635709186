import {IBaseModel} from '../../../shared/interfaces/IBaseModel';
import {ILocation} from '../../../shared/interfaces/ICleaning';
import {IPagination} from '../../../shared/interfaces/IPagination';
import {requests} from '../../../utils/api/api.service';
import {LOCATIONS_URL, ADMIN_LOCATIONS_URL, BASE_URL} from '../../../utils/api/endpoints';

export const LocationService = {
    getAllLocations: (pagination: IPagination): Promise<IBaseModel<ILocation[]>> =>
        requests.get(LOCATIONS_URL, pagination),

    deleteLocationById: (locationId: number): Promise<''> =>
        requests.delete(`${ADMIN_LOCATIONS_URL}/${locationId}`),

    createLocation: (payload: Partial<ILocation>): Promise<ILocation> =>
        requests.post(LOCATIONS_URL, payload),

    getLocationById: (locationId: number): Promise<ILocation> =>
        requests.get(`${LOCATIONS_URL}/${locationId}`),

    updateLocation: (payload: Partial<ILocation>): Promise<ILocation> =>
        requests.put(`${LOCATIONS_URL}/${payload?.id}`, payload),

    adminGenerateQR: (locationId: number): Promise<ILocation> =>
        requests.put(`${BASE_URL}/admin/location/${locationId}`, {})
}

