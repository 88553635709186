import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './languages/de.json';
import en from './languages/en.json';
import sr from './languages/sr.json';

export const resources = { // list of languages
    en: {
        translation: en,
    },
    de: {
        translation: de,
    },
    sr: {
        translation: sr,
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        compatibilityJSON: 'v3',//issue on older versions of phone
        resources,
        defaultNS: 'translation',
        fallbackLng: 'en',
    });

export default i18n;