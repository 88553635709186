import React, {SetStateAction, useState} from 'react';
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import GlobalCard from '../../../../shared/components/GlobalCard';
import {ERole} from '../../../../shared/enums/ERole';
import {IUser} from '../../../../shared/interfaces/IUser';
import {VscEye} from 'react-icons/vsc';
import {VscEyeClosed} from 'react-icons/vsc';

interface IProps {
    currentUser: IUser;
    setCurrentUser: React.Dispatch<SetStateAction<IUser>>
}

const UserForm = ({currentUser, setCurrentUser}: IProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setCurrentUser(prev => ({...prev, [name]: value}))
    }
    return (
        <Col md={8} className={'px-0'}>
            <GlobalCard>
                <Row>
                    <Col md={3} className={'ps-0 mb-2'}>
                        <label htmlFor="role" className="form-label required">
                            {t('general.role')}
                        </label>
                        <select
                            value={currentUser?.role}
                            onChange={handleChange}
                            disabled={currentUser?.role === ERole.ADMIN}
                            name="role" className="form-select">
                            {[ERole.USER, ERole.CLIENT, currentUser?.role === ERole.ADMIN ? ERole.ADMIN : '']
                                ?.filter(Boolean)
                                ?.map(role => {
                                    return (
                                        <option value={role} key={role}>
                                            {role}
                                        </option>
                                    )
                                })}
                        </select>
                    </Col>
                    <Col md={3} className={'ps-0 mb-2'}>
                        <label htmlFor="firstName" className="form-label required">
                            {t('general.firstName')}
                        </label>
                        <input
                            value={currentUser?.firstName}
                            onChange={handleChange}
                            name={'firstName'}
                            id={'firstName'}
                            type="text" required placeholder={t('general.firstName')} className="form-control"/>
                    </Col>
                    <Col md={3} className={'ps-0 mb-2'}>
                        <label htmlFor="lastName" className="form-label required">
                            {t('general.lastName')}
                        </label>
                        <input type="text" required
                               id={'lastName'}
                               onChange={handleChange}
                               name={'lastName'} value={currentUser?.lastName}
                               placeholder={t('general.lastName')} className="form-control"/>
                    </Col>
                    <Col md={3} className={'ps-0 mb-2'}>
                        <label htmlFor="email" className="form-label required">
                            {t('general.email')}
                        </label>
                        <input type="email"
                               value={currentUser?.email}
                               onChange={handleChange}
                               id={'email'}
                               name={'email'} required placeholder={t('general.email')} className="form-control"/>
                    </Col>

                    <Col md={3} className={'ps-0 mb-2'}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className="fw-semi-bold"> {t('general.password')} </Form.Label>
                            <InputGroup className="mb-3">
                                <input type={showPassword ? 'text' : 'password'} required={!currentUser?.id}
                                       id={'password'}
                                       className={'form-control'}
                                       onChange={handleChange}
                                       name={'password'} value={currentUser?.password}
                                       placeholder={t('general.password')}/>
                                <InputGroup.Text>
                                    {!showPassword ?
                                        <VscEye className="cursor-pointer" onClick={() => {
                                            setShowPassword((prev) => !prev);
                                        }
                                        }/> : <VscEyeClosed className="cursor-pointer" onClick={() => {
                                            setShowPassword((prev) => !prev);
                                        }
                                        }/>}
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col md={3} className={'mt-1 ps-0 '}>
                        <div className="d-flex flex-column align-items-start mb-3">
                            <label htmlFor="canEditTime" className="form-label"> Manual QR enabled </label>
                            <input type="checkbox" id="canEditTime"
                                   className="form-check-input mt-2"
                                   checked={currentUser?.canEditTime}
                                   onChange={(e) => {
                                       setCurrentUser(prev => ({...prev, canEditTime: e.target.checked}))
                                   }}/>
                        </div>
                    </Col>

                    <Col md={12} className={'px-0 border-top pt-3'}>
                        <div className="d-flex align-items-center justify-content-end">
                            <Button size={'sm'}
                                    type={'button'}
                                    onClick={() => navigate(-1)}
                                    variant={'outline-primary'}>
                                {t(`buttons.cancel`)}
                            </Button>
                            <Button size={'sm'}
                                    className={'ms-2'} type={'submit'}>
                                {t(`buttons.${currentUser?.id ? 'update' : 'create'}`)}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </GlobalCard>
        </Col>
    );
};

export default UserForm;
