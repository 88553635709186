import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EStatus } from '../../../../shared/enums/EStatus';
import { handleError } from '../../../../shared/functions/handleError';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { IUser } from '../../../../shared/interfaces/IUser';
import { UserService } from '../../users/service';

interface IProps {
    total: number;
    locationName?: string | null;
    changeFilterHandler: (name: string, value: string) => void;
}

const SingleCleaningFilter = ({ locationName, total, changeFilterHandler }: IProps) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState<IUser[]>([]);
    const { pagination } = usePagination({ perPage: 0 });

    useEffect(() => {
        UserService.getAllUsers(pagination)
            .then(response => {
                setUsers(response?.data)
            }).catch(err => handleError(err))
    }, [pagination]);

    return (
        <>
            <Col md={12} className={'ps-0 mb-1'}>
                <h5 className="mb-0">
                    {t('admin.waitingForApproval', { nb: total, locationName })}
                </h5>
            </Col>
            <Col md={12} className={'ps-0 mb-3'}>
                <div className="d-flex align-items-center justify-content-between ">
                    <select name="userId" className="form-select"
                        onChange={(e) => {
                            changeFilterHandler('userId', e.target.value)
                        }}
                    >
                        <option hidden>
                            {t('general.filterByUser')}
                        </option>
                        {users?.map(user => {
                            return (
                                <option value={user?.id} key={user?.id}>
                                    {user?.firstName} {user?.lastName}
                                </option>
                            )
                        })}
                        <option value="">All</option>
                    </select>
                    <select name="status" className="form-select ms-5"
                        onChange={(e) => {
                            changeFilterHandler('status', e.target.value)
                        }}
                    >
                        <option hidden>
                            {t('general.filterByStatus')}
                        </option>
                        {Object.keys(EStatus)?.map(status => {
                            return (
                                <option value={status} key={status}>
                                    {status}
                                </option>
                            )
                        })}
                        <option value="">All</option>
                    </select>
                </div>
            </Col>
        </>
    );
};

export default SingleCleaningFilter;
