import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../../shared/interfaces/IUser';
// import {IUser} from '../../model/user/IUser';

interface AuthState {
    isAuthenticated: boolean;
    token: string;
    userData?: IUser;
}

const initialState: AuthState = {
    isAuthenticated: false,
    token: '',
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginState: (state, action) => {
            state = action.payload;
            return state;
        },
        setLogoutState: (state) => {
            state = initialState;
            return state;
        },
    },
});

export default authSlice;
