import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useParams, useSearchParams} from 'react-router-dom';
import InfinityLoader from '../../../shared/components/InfiniteLoader';
import {handleError} from '../../../shared/functions/handleError';
import {usePagination} from '../../../shared/hooks/usePagination';
import {ICleaning} from '../../../shared/interfaces/ICleaning';
import {CleaningService} from '../location-cleaning-list/service';
import SingleCleaningCard from './components/SingleCleaningCard';
import SingleCleaningFilter from './components/SingleCleaningFilter';

const SingleCleaning = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const {pagination, count, setCount, changeFilterHandler} = usePagination({
        perPage: 20,
        paginationKeys: {locationId: id}
    })
    const [cleanings, setCleanings] = useState<ICleaning[]>([])

    useEffect(() => {
        if (!!id) {
            CleaningService.getSingleCleaning(pagination).then(response => {
                setCleanings(response?.data);
                setCount(response?.meta?.total)
            }).catch(err => handleError(err))
        }
    }, [id, pagination, setCount])

    return (
        <>
            <Row>
                <SingleCleaningFilter
                    changeFilterHandler={changeFilterHandler}
                    locationName={searchParams.get('locationName')} total={count}
                />
            </Row>
            <InfinityLoader dataLength={cleanings?.length} hasMore={count > pagination?.perPage}
                            showSeenAll={pagination?.perPage > 20}
                            fetchData={() => changeFilterHandler('perPage', pagination?.perPage + 20)}>
                <Row>
                    {!!cleanings?.length ? cleanings?.map(cleaning => {
                        return (
                            <Col md={3} className={'ps-0 mb-3'} key={cleaning?.id}>
                                <SingleCleaningCard cleaning={cleaning} changeFilterHandler={changeFilterHandler}/>
                            </Col>
                        )
                    }) : <Col md={12} className={'px-0'}>
                        {t('general.listEmpty')}
                    </Col>}
                </Row>
            </InfinityLoader>
        </>
    );
};

export default SingleCleaning;
