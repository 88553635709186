import React, {useEffect, useState} from 'react'
import {useParams, useSearchParams} from 'react-router-dom';
import {UserCleaningService} from '../../create-cleaning/service';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../store/store';
import {IUser} from '../../../../shared/interfaces/IUser';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {handleError} from '../../../../shared/functions/handleError';
import {Col, Row} from 'react-bootstrap';
import SingleCleaningCard from '../../../admin/cleaning-list/components/SingleCleaningCard';
import {ICleaning} from '../../../../shared/interfaces/ICleaning';
import {useTranslation} from 'react-i18next';
import LocationCleaningFilter from './LocationCleaningFilter';
import InfiniteLoader from '../../../../shared/components/InfiniteLoader';

export default function LocationCleaningCards() {

    const {id: locationId} = useParams();
    const [searchParams] = useSearchParams();

    const {id} = useSelector((state: RootState) => state.auth.userData) as IUser;
    const {pagination, count, setCount, changeFilterHandler} = usePagination({perPage: 24});
    const {t} = useTranslation();
    const [locationCleanings, setLocationCleanings] = useState<ICleaning[]>([]);

    useEffect(() => {
        id && UserCleaningService.getCleanings(id, {...pagination, locationId})
            .then(response => {
                setLocationCleanings(response?.data);
                setCount(response?.meta?.total);
            })
            .catch(err => handleError(err));
    }, [id, locationId, pagination, setCount]);

    return (
        <>
            <Row className={'border-bottom my-3'}>
                <LocationCleaningFilter total={count} locationName={searchParams.get('locationName')}
                                        changeFilterHandler={changeFilterHandler}/>
            </Row>

            <InfiniteLoader dataLength={locationCleanings?.length} hasMore={count > pagination?.perPage}
                            showSeenAll={pagination?.perPage > 24}
                            fetchData={() => changeFilterHandler('perPage', pagination?.perPage + 10)}>
                <Row>
                    {!!locationCleanings?.length ? locationCleanings?.map(cleaning => {
                        return (
                            <Col md={3} className={'ps-0 mb-3'} key={cleaning?.id}>
                                <SingleCleaningCard cleaning={cleaning} changeFilterHandler={changeFilterHandler}/>
                            </Col>
                        )
                    }) : <Col md={12} className={'px-0'}>
                        {t('general.listEmpty')}
                    </Col>}
                </Row>
            </InfiniteLoader>
        </>
    )
}
