import React from 'react';
import Header from './Header';
import Footer from './Footer';

interface IProps {
    children: JSX.Element;
}


export default function Layout({ children }: IProps) {
    return (
        <>
            <Header />
            <div className="container my-100px">
                {children}
            </div>
            <Footer />
        </>
    )
}
