import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface IProps {
    show: boolean,
    handleClose: () => void,
    handleConfirm: () => void,
    title: string
}

export default function DeleteConfirmationModal({ show, handleClose, title, handleConfirm }: IProps) {
    const { t } = useTranslation();

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t("general.confirmDelete")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("general.confirmDeleteDesc", { name: title })}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" className="text-white" size="sm" onClick={handleClose}>
                    {t("buttons.cancel")}
                </Button>
                <Button variant="danger" className='text-white' size="sm" onClick={handleConfirm}>
                    {t("buttons.delete")}
                </Button>
            </Modal.Footer>
        </Modal>)
}
