import React, { SetStateAction, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import GlobalCard from '../../../../shared/components/GlobalCard';
import { useNavigate } from 'react-router-dom';
import { handleError } from '../../../../shared/functions/handleError';
import { ICreateCleaning } from '../../../../shared/interfaces/ICleaning';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { multipartFormData } from '../../../../utils/api/api.service';
import { USERS_URL } from '../../../../utils/api/endpoints';
import CreateCleaningForm from './CreateCleaningForm';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function CreateCleaning() {
    const navigate = useNavigate();
    const currentUser = useSelector((state: RootState) => state.auth.userData);
    const { t } = useTranslation();
    const [currentCleaning, setCurrentCleaning] = useState<ICreateCleaning | null>(null);
    const [files, setFiles] = useState<FileList | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        setIsLoading(true);
        multipartFormData(files && files, `${USERS_URL}/${currentUser?.id}/cleanings`, currentCleaning, ['street', 'locationId'], 'post', 'file', true).then(response => {
            if (response) {
                toast.success(t("general.successfullyCreated"));
                navigate(-1);
                setCurrentCleaning(null);
            }
        }).catch(err => handleError(err, t)).finally(() => {
            setIsLoading(false);
        })
    }
    return (
        <Row className="justify-content-center">
            <Col md={8} className={'px-0'}>
                <GlobalCard>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}>
                        <Row className={'mt-2'}>
                            <CreateCleaningForm currentCleaning={currentCleaning as ICreateCleaning}
                                files={files}
                                setFiles={setFiles}
                                setCurrentCleaning={setCurrentCleaning as React.Dispatch<SetStateAction<ICreateCleaning>>}
                                isLoading={isLoading} />
                        </Row>
                    </Form>
                </GlobalCard>
            </Col>
        </Row>
    )
}


