import React from 'react';
import {Button, Card, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {formatDate} from '../../../../shared/functions/formatDate';
import {handleError} from '../../../../shared/functions/handleError';
import {ITimereg} from '../../../../shared/interfaces/ITimereg';
import {BASE_URL} from '../../../../utils/api/endpoints';
import {AdminTimeregService} from '../service';

interface IProps {
    pagination: any;
    timereg: ITimereg;
}

const TimeregCard = ({timereg, pagination}: IProps) => {
    const {t} = useTranslation();
    const handleDownloadTimereg = async (userId: number) => {
        !!userId && await AdminTimeregService.downloadTimereg(userId, pagination)
            .then(response => {
                const excelFile = BASE_URL + '/' + response?.url;
                window.open(excelFile);
                toast.success(t('general.successfullyDownloaded'))
            }).catch(err => handleError(err));
    }
    return (
        <Col md={6} className={'mb-3 ps-0'}>
            <Card>
                <Card.Body>
                    <div className="d-flex align-items-center">
                        <h6 className="mb-0">
                            {t('general.name')}:
                        </h6>
                        <h6 className="mb-0 fw-normal ms-1">
                            {timereg?.firstName} {timereg?.lastName}
                        </h6>
                    </div>
                    <div className="d-flex align-items-center">
                        <h6 className="mb-0">
                            {t('general.email')}:
                        </h6>
                        <h6 className="mb-0 fw-normal ms-1">
                            {timereg?.email}
                        </h6>
                    </div>
                    <div className="d-flex align-items-center">
                        <h6 className="mb-0">
                            {t('general.nbOfCleanings',
                                {
                                    from: formatDate(pagination?.fromDate),
                                    to: formatDate(pagination?.toDate)
                                }
                            )}:
                        </h6>
                        <h6 className="mb-0 fw-normal ms-1">
                            {timereg?.userLocationTimeregs?.length}
                        </h6>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mb-0 text-muted">
                            ID #{timereg?.id}
                        </h6>
                        <div>
                            <Button variant={'primary'} className={'ms-1'}
                                    onClick={() => handleDownloadTimereg(timereg?.userLocationTimeregs?.[0]?.userId)}
                                    size={'sm'}>
                                {t('buttons.download')}
                            </Button>
                        </div>
                    </div>
                </Card.Footer>
            </Card>
        </Col>
    );
};

export default TimeregCard;
