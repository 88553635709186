import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../../shared/functions/handleError';
import { usePagination } from '../../../shared/hooks/usePagination';
import { IPagination } from '../../../shared/interfaces/IPagination';
import { IUser } from '../../../shared/interfaces/IUser';
import UserCard from './components/UserCard';
import UsersFilter from './components/UsersFilter';
import { UserService } from './service';
import InfiniteLoader from '../../../shared/components/InfiniteLoader';

export default function UserList() {
    const { t } = useTranslation();
    const [userList, setUserList] = useState<IUser[]>([]);
    const { pagination, count, setCount, changeFilterHandler, setPagination } = usePagination(
        { perPage: 24 }
    );

    useEffect(() => {
        UserService.getAllUsers(pagination)
            .then(response => {
                setUserList(response?.data);
                setCount(response?.meta?.total);
            }).catch(err => handleError(err))
    }, [pagination, setCount])

    return (
        <>
            <Row>
                <UsersFilter count={count} changeFilterHandler={changeFilterHandler} />
            </Row>
            <InfiniteLoader dataLength={userList?.length}
                hasMore={count > pagination?.perPage}
                showSeenAll={pagination?.perPage > 24}
                fetchData={() => setPagination((prev: IPagination) => ({
                    ...prev,
                    perPage: prev?.perPage + 20
                }))}>
                <Row>
                    {!!userList?.length ? userList?.map(user => {
                        return (
                            <UserCard key={user?.id} changeFilterHandler={changeFilterHandler} user={user} />
                        )
                    }) : <Col md={12} className={'mt-3 px-0'}>
                        <h5 className="mb-0">
                            {t('general.listEmpty')}
                        </h5>
                    </Col>}
                </Row>
            </InfiniteLoader>
        </>
    )
}
