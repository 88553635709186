import { IBaseModel } from '../../../shared/interfaces/IBaseModel';
import { IPagination } from '../../../shared/interfaces/IPagination';
import { IUser } from '../../../shared/interfaces/IUser';
import { requests } from '../../../utils/api/api.service';
import { ADMIN_CLIENT_URL, ADMIN_USER_URL } from '../../../utils/api/endpoints';

export const UserService = {
    getAllUsers: (pagination: IPagination): Promise<IBaseModel<IUser[]>> =>
        requests.get(ADMIN_USER_URL, pagination),
    updateUser: (payload: Partial<IUser>): Promise<IUser> =>
        requests.put(`${ADMIN_USER_URL}/${payload?.id}`, payload),
    createUser: (payload: Partial<IUser>): Promise<IUser> =>
        requests.post(ADMIN_USER_URL, payload),
    getUserById: (userId: number): Promise<IUser> =>
        requests.get(`${ADMIN_USER_URL}/${userId}`),
    getAllClients: (): Promise<IBaseModel<IUser[]>> =>
        requests.get(ADMIN_CLIENT_URL),
    deleteUserById: (userId: number): Promise<''> =>
        requests.delete(`${ADMIN_USER_URL}/${userId}`),
}