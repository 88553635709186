import React from 'react'
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import { getInitialRoute } from '../../shared/functions/getInitialRoute';

export default function NotFound() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isAuthenticated, userData } = useSelector((state: RootState) => state.auth);

    return (
        <div className="text-center">
            <h1> 404 </h1>
            <h4> {t("error.error-not-found")} </h4>
            <Button variant="primary" className='mt-3'
                onClick={() => {
                    if (isAuthenticated && userData?.role) {
                        navigate(getInitialRoute(userData?.role));
                    }
                }}>
                {t("buttons.backToHomepage")}
            </Button>
        </div>
    )
}
