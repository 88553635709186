import React from 'react'
import { Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import CleaningFilter from '../../../admin/location-cleaning-list/components/CleaningFilter';

interface IProps {
    total: number,
    locationName: string | null,
    changeFilterHandler: (name: string, value: string) => void
}

export default function LocationCleaningFilter({ total, locationName, changeFilterHandler }: IProps) {
    const { t } = useTranslation();

    return (
        <>
            <Col md={6} className={'px-0'}>
                <h6 className='mb-0 pt-2'>
                    {t("user.displaying", { nb: total, locationName: locationName })}
                </h6>
            </Col>

            <Col md={6} className="d-flex justify-content-end px-0">
                <CleaningFilter changeFilterHandler={changeFilterHandler} showStatusFilter={true} />
            </Col>

        </>
    )
}
