import React from 'react'
import { Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
    count: number
    changeFilterHandler: (name: string, value: string) => void;
}
export default function LocationsFilter({ count, changeFilterHandler }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <Col md={12} className={'px-0 d-flex justify-content-between align-items-center border-bottom pb-2'}>

                <h6 className='mb-0'>
                    {t("admin.locations", { nb: count })}
                </h6>

                <Button variant="primary" onClick={() => navigate('/admin/location/create')}>
                    {t("buttons.create")}
                </Button>
            </Col>

            <Col md={12} className={'px-0 mt-3'}>
                <input type="text" className="form-control" placeholder={t("general.typeSomething")}
                    onChange={(e) => changeFilterHandler('name', e.target.value)} />
            </Col>
        </>
    )
}
