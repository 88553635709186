import axios, { AxiosResponse } from 'axios';

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'text/html',
    },
};
export const instance = axios.create(defaultOptions);

export const setupInterceptors = () => {

    instance.interceptors.response.use(res => {
        // success
        return res;
    }, async (err) => {
        return Promise.reject(err);
    });
};

instance.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('token') ?? '{}');
    const appVersion = localStorage.getItem('appVersion');
    config!.headers!['app-version'] = appVersion ?? '';
    config!.headers!.Authorization = token ? `Bearer ${token}` : '';
    return config
}, (error) => {
    return Promise.reject(error);
});

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
    get: (url: string, params?: any) => instance.get(url, { params }).then(responseBody).catch(e => {
        throw e;
    }),
    post: (url: string, body: {}, customHeaders?: any) => instance.post(url, body, customHeaders).then(responseBody).catch(e => {
        throw e;
    }),
    put: (url: string, body: {}, customHeaders?: any) => instance.put(url, body, customHeaders).then(responseBody).catch(e => {
        throw e;
    }),
    delete: (url: string) => instance.delete(url).then(responseBody).catch(e => {
        throw e;
    }),
};

export async function multipartFormData(image: any, url: string, body?: any, bodyKeys?: string[], method = 'put', formKey = 'file', isMultiple = false, progressCallback?: (progress: number) => void) {
    const formData = new FormData();
    if (isMultiple) {
        for (const item of image) {
            formData.append(formKey, item);
        }
    } else {
        formData.append(formKey, image);
    }
    for (const item of bodyKeys || []) {
        formData.append(item, body[item]);
    }
    if (method === 'put') {
        return instance.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } else {
        return instance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export const downloadFile = (url: string, format?: string, fileName?: string) => {
    const token = JSON.parse(localStorage.getItem('token') ?? '{}');
    const appVersion = localStorage.getItem('appVersion');

    const method = 'GET';
    axios.request({
        url,
        method,
        responseType: 'blob',
        headers: {
            'app-version': appVersion || '1.0.0',
            Authorization: token ? `Bearer ${token}` : '',
        },
    })
        .then((response) => {
            fileName = response.headers['content-disposition'].slice(21);
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', format ? `${fileName}.${format}` : `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error => console.error(error));
};