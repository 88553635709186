import {BiLocationPlus, BiPencil} from 'react-icons/bi';
import {CgUser} from 'react-icons/cg';
import {CiCircleList} from 'react-icons/ci';
import {FaQrcode} from 'react-icons/fa';
import SingleCleaning from '../../../app/admin/cleaning-list';
import SingleCleaningUpdate from '../../../app/admin/cleaning-list/components/SingleCleaningForm';
import LocationCleaningList from '../../../app/admin/location-cleaning-list';
import LocationList from '../../../app/admin/location-list';
import LocationCreate from '../../../app/admin/location-list/components/LocationCreate';
import LocationUpdate from '../../../app/admin/location-list/components/LocationUpdate';
import AdminLocationTimeregList from '../../../app/admin/location-timereg-list';
import UserList from '../../../app/admin/users';
import UserCreate from '../../../app/admin/users/components/UserCreate';
import UserUpdate from '../../../app/admin/users/components/UserUpdate';
import NotFound from '../../../authentification/404';
import UserCreateCleaning from '../../../app/user/create-cleaning';
import CreateCleaning from '../../../app/user/create-cleaning/components/CreateCleaning';

export const adminRoutes = [
    {
        path: '/admin/location-cleaning-list',
        showInFooter: true,
        icon: <CiCircleList/>,
        title: 'user.cleaningList',
        component: <LocationCleaningList/>
    },
    {
        path: '/admin/cleaning/:id',
        icon: <CiCircleList/>,
        component: <SingleCleaning/>
    },

    {
        path: '/admin/cleaning/update/:id',
        icon: <CiCircleList/>,
        component: <SingleCleaningUpdate/>
    },
    {
        path: '/admin/user-list',
        showInFooter: true,
        icon: <CgUser/>,
        title: 'user.users',
        component: <UserList/>
    },
    {
        path: '/admin/user/update/:id',
        component: <UserUpdate/>
    },

    {
        path: '/admin/user/create',
        component: <UserCreate/>
    },
    {
        path: '/admin/user/:id',
        icon: <CiCircleList/>,
        component: <UserList/>
    },
    {
        path: '/admin/location-list',
        showInFooter: true,
        icon: <BiLocationPlus/>,
        title: 'user.locations',
        component: <LocationList/>

    },
    {
        path: '/admin/location-timereg-list',
        showInFooter: true,
        icon: <FaQrcode/>,
        title: 'admin.timeReg',
        component: <AdminLocationTimeregList/>
    },
    {
        path: '/admin/location/create',
        component: <LocationCreate/>
    },
    {
        path: '/admin/location/update/:id',
        component: <LocationUpdate/>
    },
    {
        path: '/admin/cleaning/create',
        showInFooter: true,
        icon: <BiPencil/>,
        title: 'user.createCleaning',
        component: <UserCreateCleaning/>
    },
    {
        path: '/admin/cleaning/create/form',
        component: <CreateCleaning/>
    },
    {
        path: '*',
        component: <NotFound/>
    }
]