import React, { useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteConfirmationModal from '../../../../shared/components/DeleteConfirmationModal';
import { ERole } from '../../../../shared/enums/ERole';
import { formatDate } from '../../../../shared/functions/formatDate';
import { handleError } from '../../../../shared/functions/handleError';
import { IUser } from '../../../../shared/interfaces/IUser';
import { UserService } from '../service';

interface IProps {
    changeFilterHandler: (name: string, value: string) => void;
    user: IUser;
}

const userCardKeys = ['firstName', 'lastName', 'email', 'role']

const UserCard = ({ user, changeFilterHandler }: IProps) => {
    const navigate = useNavigate();
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)
    const { t } = useTranslation();

    const handleDeleteUser = async () => {
        !!user?.id && await UserService.deleteUserById(user?.id).then(() => {
            toast.success(t('general.successfullyDeleted'));
            setIsDeleteModalOpened(false);
            changeFilterHandler('updatedAt', `${new Date().getTime()}`)
        }).catch(err => handleError(err))
    }

    return (
        <>
            <Col md={3} className={'ps-0 mt-3 '}>
                <Card className={user?.deleted ? 'bg-danger bg-opacity-25' : ''}>
                    <Card.Body>
                        {userCardKeys?.map(cardKey => {
                            return (
                                <div className="d-flex align-items-center mb-1" key={cardKey}>
                                    <h6 className="mb-0">
                                        {t(`general.${cardKey}`)}:
                                    </h6>
                                    <h6 className="mb-0 ms-1 fw-normal">
                                        {user?.[cardKey as keyof typeof user]}
                                    </h6>
                                </div>
                            )
                        })}
                        <div className="d-flex align-items-center">
                            <h6 className="mb-0">
                                {t('admin.createdAt')}:
                            </h6>
                            <h6 className="mb-0 ms-1 fw-normal">
                                {formatDate(user?.createdAt as string)}
                            </h6>
                        </div>
                        <div className='d-flex align-items-center mt-1'>
                            <h6 className='mb-0'>
                                {t("user.canEditTime")}:
                            </h6>

                            <h6 className='mb-0 ms-1 fw-normal'> {t(`general.${user?.canEditTime ? 'yes' : 'no'}`)} </h6>

                        </div>
                    </Card.Body>
                    {user?.deleted ? <Card.Footer>
                        <p className="mb-0 text-center text-muted">
                            This user is deleted!
                        </p>
                    </Card.Footer> : <Card.Footer>
                        <div className="d-flex align-items-center justify-content-between">
                            <h6 className="mb-0 text-muted">
                                ID #{user?.id}
                            </h6>
                            <div>
                                {user?.role !== ERole.ADMIN && <Button variant={'outline-danger'}
                                    onClick={() => setIsDeleteModalOpened(true)}
                                    size={'sm'}>
                                    {t('buttons.delete')}
                                </Button>}
                                <Button variant={'primary'} className={'ms-1'}
                                    onClick={() => navigate(`/admin/user/update/${user?.id}`)}
                                    size={'sm'}>
                                    {t('buttons.open')}
                                </Button>
                            </div>
                        </div>
                    </Card.Footer>}
                </Card>
            </Col>
            <DeleteConfirmationModal show={isDeleteModalOpened}
                handleClose={() => setIsDeleteModalOpened(false)}
                handleConfirm={handleDeleteUser} title={`${user?.firstName} ${user?.lastName}`} />
        </>
    );
};

export default UserCard;
