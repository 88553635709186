export const BASE_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_MODE
        : process.env.REACT_APP_PRO_MODE;

export const LOGIN_URL = BASE_URL + '/login';

export const ME_URL = BASE_URL + '/me';

export const LOCATIONS_URL = BASE_URL + '/locations';

export const ADMIN_USER_URL = BASE_URL + '/admin/users';

export const ADMIN_CLIENT_URL = BASE_URL + '/admin/clients';

export const ADMIN_CLEANING_URL = BASE_URL + '/admin/cleaning';

export const ADMIN_LOCATIONS_URL = BASE_URL + '/admin/locations';

export const ADMIN_TIMEREG_ULR = BASE_URL + '/admin/user-location-timereg';
// USER ROUTES

export const USERS_URL = BASE_URL + '/users';

