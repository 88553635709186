import React, { useState } from 'react'
import { Button, Card, Col } from 'react-bootstrap'
import { ILocation } from '../../../../shared/interfaces/ICleaning';
import { formatDate } from '../../../../shared/functions/formatDate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from '../../../../shared/components/DeleteConfirmationModal';
import { LocationService } from '../service';
import { toast } from 'react-toastify';
import { handleError } from '../../../../shared/functions/handleError';
import { BASE_URL } from '../../../../utils/api/endpoints';

interface IProps {
    location: ILocation;
    changeFilterHandler: (name: string, value: string) => void;
}

export default function LocationCard({ location, changeFilterHandler }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);


    const handleDeleteLocation = async () => {
        !!location?.id && await LocationService.deleteLocationById(location?.id).then(() => {
            toast.success(t("general.successfullyDeleted"));
            setIsDeleteModalOpened(false);
            changeFilterHandler('updatedAt', `${new Date().getTime()}`);
        }).catch(err => handleError(err))
    };

    return (
        <>
            <Col md={4} className={'ps-0 mt-3'}>
                <Card>
                    <Card.Body>
                        <div className='d-flex align-items-center'>
                            <h6 className='mb-0'> {t("general.name")}: </h6>
                            <span className="ms-1"> {location?.name} </span>
                        </div>

                        <div className="d-flex align-items-center">
                            <h6 className="mb-0"> {t("admin.createdAt")}: </h6>
                            <span className="ms-1"> {formatDate(location?.createdAt, "DD.MM.YYYY - HH:mm")} </span>
                        </div>

                    </Card.Body>
                    <Card.Footer className={'d-flex align-items-center justify-content-between'}>
                        <div>
                            <h6 className="mb-0 text-muted"> ID #{location.id} </h6>
                        </div>
                        <div>
                            <Button variant="outline-danger" size="sm"
                                onClick={() => setIsDeleteModalOpened(true)}>
                                {t("buttons.delete")}
                            </Button>
                            {/*  */}
                            <a target="_blank"
                                rel="noreferrer"
                                href={`${BASE_URL}/location/${location?.id}`}>
                                <Button variant="outline-primary" size="sm" className="mx-1">
                                    {t("buttons.preview")}
                                </Button>
                            </a>

                            <Button variant="primary" size="sm"
                                onClick={() => navigate(`/admin/location/update/${location?.id}`)}>
                                {t("buttons.open")}
                            </Button>

                        </div>
                    </Card.Footer>
                </Card>
            </Col>

            <DeleteConfirmationModal show={isDeleteModalOpened}
                handleClose={() => setIsDeleteModalOpened(false)}
                handleConfirm={handleDeleteLocation}
                title={location?.name}
            />
        </>
    )
}
