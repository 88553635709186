import React, { SetStateAction } from 'react'
import { ILocation } from '../../../../shared/interfaces/ICleaning'
import { Button, Col, Row } from 'react-bootstrap';
import GlobalCard from '../../../../shared/components/GlobalCard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
    currentLocation: ILocation;
    setCurrentLocation: React.Dispatch<SetStateAction<ILocation>>
}

export default function LocationForm({ currentLocation, setCurrentLocation }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setCurrentLocation(prev => ({ ...prev, [name]: value }));
    }

    return (
        <Col md={8} className={'px-0'}>
            <GlobalCard>
                <Row>
                    <Col md={12} className={'px-0 '}>
                        <input
                            value={currentLocation?.name}
                            onChange={handleChange}
                            name={'name'}
                            required
                            minLength={2}
                            id={'name'}
                            type="text" placeholder={t('general.name')} className="form-control"
                        />
                    </Col>
                    <Col md={12} className={'px-0 pt-3'}>
                        <div className="d-flex align-items-center justify-content-end">
                            <Button size={'sm'}
                                type={'button'}
                                onClick={() => navigate(-1)}
                                variant={'outline-primary'}>
                                {t(`buttons.cancel`)}
                            </Button>
                            <Button size={'sm'}
                                className={'ms-2'} type={'submit'}>
                                {t(`buttons.${currentLocation?.id ? 'update' : 'create'}`)}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </GlobalCard>
        </Col>
    )
}
