import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
    count: number
    changeFilterHandler: (name: string, value: string) => void;
}

const UsersFilter = ({ count, changeFilterHandler }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <>
            <Col md={12} className={'px-0 border-bottom pb-2 d-flex justify-content-between align-items-center'}>
                <h6 className="mb-0">
                    {t('admin.users', { nb: count })}
                </h6>
                <Button variant={'primary'} onClick={() => navigate('/admin/user/create')}>
                    {t('buttons.create')}
                </Button>
            </Col>
            <Col md={12} className={'mt-3 px-0'}>
                <input type="text"
                    onChange={(e) => changeFilterHandler('name', e.target.value)}
                    placeholder={t('general.typeSomething')} className="form-control" />
            </Col>
        </>
    );
};

export default UsersFilter;
