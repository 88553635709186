import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import InfinityLoader from '../../../shared/components/InfiniteLoader';
import {formatDate, getFirstDayOfMonth} from '../../../shared/functions/formatDate';
import {handleError} from '../../../shared/functions/handleError';
import {usePagination} from '../../../shared/hooks/usePagination';
import {IPagination} from '../../../shared/interfaces/IPagination';
import {ITimereg} from '../../../shared/interfaces/ITimereg';
import TimeregCard from './components/TimeregCard';
import TimeregFilter from './components/TimeregFilter';
import {AdminTimeregService} from './service';

const AdminLocationTimeregList = () => {
    const {t} = useTranslation();
    const [timeregList, setTimeregList] = useState<ITimereg[]>([])
    const {pagination, count, setCount, setPagination, changeFilterHandler} = usePagination({
        perPage: 12,
        paginationKeys:
            {
                fromDate: formatDate(getFirstDayOfMonth(new Date()?.toISOString()) as unknown as string, 'YYYY-MM-DD'),
                toDate: formatDate(new Date()?.toISOString(), 'YYYY-MM-DD')
            }
    });
    useEffect(() => {
        AdminTimeregService.getAllTimeregs(pagination).then(response => {
            setCount(response?.meta?.total)
            setTimeregList(response?.data)
        }).catch(err => handleError(err));
        // eslint-disable-next-line
    }, [JSON.stringify(pagination), setCount])
    return (
        <>
            <Row>
                <TimeregFilter pagination={pagination} changeFilterHandler={changeFilterHandler}/>
            </Row>

            <InfinityLoader dataLength={timeregList?.length}
                            hasMore={count > pagination?.perPage}
                            showSeenAll={pagination?.perPage > 24}
                            fetchData={() => setPagination((prev: IPagination) => ({
                                ...prev,
                                perPage: prev?.perPage + 10
                            }))}>
                <Row>
                    {
                        !!timeregList.length ?
                            timeregList?.map(timereg => {
                                return (
                                    <TimeregCard key={timereg?.id} timereg={timereg} pagination={pagination}/>
                                )
                            }) :

                            <Col md={12} className={'px-0 mt-3'}>
                                <h6 className="mb-0"> {t('general.listEmpty')}</h6>
                            </Col>
                    }
                </Row>
            </InfinityLoader>
        </>
    );
};

export default AdminLocationTimeregList;
