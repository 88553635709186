import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { IUser } from '../interfaces/IUser';
import { useTranslation } from 'react-i18next';
import { routeList } from '../../utils/routes';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Footer() {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const { role } = useSelector((state: RootState) => state.auth.userData) as IUser;
    const specificUserRoutes = routeList?.find(route => route.role === role);
    const { pathname } = useLocation();
    return (
        <footer className="bg-white py-3 w-100 position-fixed bottom-0 border-top z-3">
            <div className="container d-flex align-items-center justify-content-evenly">
                {specificUserRoutes?.routes?.filter(route => route.showInFooter)?.map(route => {
                    const isActive = route?.path === pathname;
                    return (
                        <div key={route?.path}
                            onClick={() => navigation(route?.path)}
                            className={`d-flex align-items-center flex-column cursor-pointer ${isActive ? 'text-primary' : ''}`}>
                            <div>
                                {route?.icon}
                            </div>
                            {!!route?.title?.length && <div>
                                {t(route?.title)}
                            </div>}
                        </div>
                    )
                })}
            </div>
        </footer>
    )
}
