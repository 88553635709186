import React, { SetStateAction, useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap';
import { ICreateCleaning, ILocation } from '../../../../shared/interfaces/ICleaning';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocationService } from '../../../admin/location-list/service';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { handleError } from '../../../../shared/functions/handleError';
import FileUpload from '../../../../shared/components/FileUpload';
import StatusBadge from '../../../../shared/components/StatusBadge';
import { EStatus } from '../../../../shared/enums/EStatus';

interface IProps {
    files: FileList | null;
    setFiles: React.Dispatch<SetStateAction<FileList | null>>;
    currentCleaning: ICreateCleaning
    setCurrentCleaning: React.Dispatch<SetStateAction<ICreateCleaning>>
    isLoading?: boolean
}

export default function CreateCleaningForm({ currentCleaning, setCurrentCleaning, files, setFiles, isLoading }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [locationList, setLocationList] = useState<ILocation[]>([]);
    const { pagination } = usePagination({ perPage: 24 });
    const { id } = useParams();

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setCurrentCleaning((prev: ICreateCleaning | null) => ({ ...prev, [name]: value }) as ICreateCleaning);
    }

    useEffect(() => {
        LocationService.getAllLocations(pagination)
            .then(response => {
                setLocationList(response?.data);
            }).catch(err => handleError(err))
    }, [pagination]);

    return (
        <>

            {![EStatus.COMPLETED, EStatus.REJECTED].includes(currentCleaning?.status as EStatus)
                &&
                <Col md={12} className={'px-0 border-bottom pb-4'}>
                    <FileUpload files={files} setFiles={setFiles} isMultiple={true} isRequired={!currentCleaning?.id} formatType='image/*' />
                </Col>}

            {currentCleaning?.id &&
                <Col md={12} className={'px-0 mt-3'}>
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <h6> {t("general.cleaning")} #{id}</h6>
                        <StatusBadge status={currentCleaning?.status as EStatus} />
                    </div>
                </Col>
            }

            <Col md={12} className={'px-0 mt-3'}>
                <input className='form-control'
                    required
                    type="text"
                    name="street"
                    value={currentCleaning?.street}
                    onChange={changeHandler}
                    placeholder={t('general.street')}
                    disabled={[EStatus.COMPLETED, EStatus.REJECTED].includes(currentCleaning?.status as EStatus)}
                />
            </Col>

            <Col md={12} className={'px-0 mt-2'}>
                <select name="locationId" value={currentCleaning?.locationId} required className="form-select"
                    disabled={[EStatus.COMPLETED, EStatus.REJECTED].includes(currentCleaning?.status as EStatus)}
                    onChange={changeHandler}>
                    <option hidden> {t("general.selectAddress")} </option>
                    {locationList?.map((location) => {
                        return (<option key={location?.id} value={location?.id}> {location?.name} </option>)
                    })}
                </select>

            </Col>

            <Col md={12} className={'px-0 mt-3'}>
                <div className="d-flex justify-content-end">
                    <Button variant="outline-primary" className="me-1" disabled={isLoading} size="sm"
                        onClick={() => navigate(-1)}>
                        {(t("buttons.cancel"))}
                    </Button>

                    {![EStatus.COMPLETED, EStatus.REJECTED].includes(currentCleaning?.status as EStatus) &&

                        <Button variant="primary"
                            disabled={isLoading}
                            size="sm" type="submit">
                            {isLoading && <span className="spinner-border spinner-border-sm me-1" role="status"
                                aria-hidden="true"></span>}
                            {t(`buttons.${currentCleaning?.status ? 'update' : 'create'}`)}
                        </Button>}
                </div>
            </Col>
        </>
    )
}
