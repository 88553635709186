import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store/store';
import { IUser } from '../../../shared/interfaces/IUser';
import { useSelector } from 'react-redux';

const UserCreateCleaning = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { role } = useSelector((state: RootState) => state.auth.userData) as IUser;

    return (
        <Row>
            <Col md={12} className='d-flex justify-content-center'>
                <Button variant="primary" onClick={() => navigate(`/${role?.toLowerCase()}/cleaning/create/form`)}>
                    {t("buttons.createCleaning")}
                </Button>
            </Col>
        </Row>
    );
};

export default UserCreateCleaning;
