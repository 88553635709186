import React, {useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Fancybox from '../../../../shared/components/Fancybox';
import GlobalCard from '../../../../shared/components/GlobalCard';
import ImageHandler from '../../../../shared/components/ImageHandler';
import StatusBadge from '../../../../shared/components/StatusBadge';
import {EStatus} from '../../../../shared/enums/EStatus';
import {formatDate} from '../../../../shared/functions/formatDate';
import {generatePhotoUrl} from '../../../../shared/functions/generatePhotoUrl';
import {handleError} from '../../../../shared/functions/handleError';
import {ICleaning} from '../../../../shared/interfaces/ICleaning';
import {IUser} from '../../../../shared/interfaces/IUser';
import {RootState} from '../../../../store/store';
import {ClientCleaningService} from '../service';

const CleaningClientUpdate = () => {
    const {id} = useParams();
    const {id: userId} = useSelector((state: RootState) => state.auth.userData) as IUser;
    const [cleaning, setCleaning] = useState<ICleaning | null>(null);
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!id && !!userId) {
            ClientCleaningService
                .getCleaningById(id, userId)
                .then(response => {
                    setCleaning(response);
                }).catch(err => handleError(err))
        }
    }, [id, userId]);

    return (
        <Row className={'justify-content-center'}>
            <Col md={8}>
                <GlobalCard>
                    <Fancybox
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}
                    >
                        <Row>
                            {cleaning?.photos?.map(photo => {
                                return (
                                    <Col md={3} key={photo?.id} className={'mb-3'}>

                                        <a data-fancybox="gallery" href={generatePhotoUrl(photo?.url)}>
                                            <ImageHandler src={generatePhotoUrl(photo?.url)}
                                                          className={'w-100 h-150px img-cover card-border-radius cursor-pointer'}/>
                                        </a>

                                    </Col>
                                )
                            })}
                            <Col md={12} className="ps-0 border-bottom py-2">
                                <div>
                                    <span
                                        className="fw-bold"> {t('general.firstName') + ' & ' + t('general.lastName')}: </span>
                                    <span> {cleaning?.user?.firstName} {cleaning?.user?.lastName}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.location')}: </span>
                                    <span> {cleaning?.location?.name}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.street')}: </span>
                                    <span> {cleaning?.street}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.createdAt')}: </span>
                                    <span> {formatDate(cleaning?.createdAt as string, 'DD.MM.YYYY - HH:mm')}</span>
                                </div>

                                <div>
                                    <span className="fw-bold"> {t('general.status')}: </span>
                                    <StatusBadge status={cleaning?.status as EStatus}/>
                                </div>
                            </Col>

                            <Col md={12} className="px-0 my-1">
                                <Button className="w-100" variant="outline-primary"
                                        onClick={() => navigate(-1)}> {t('buttons.cancel')} </Button>
                            </Col>

                        </Row>
                    </Fancybox>
                </GlobalCard>
            </Col>
        </Row>
    );
};

export default CleaningClientUpdate;
