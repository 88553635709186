import {ERole} from '../../shared/enums/ERole';
import {adminRoutes} from './roles/admin.routes';
import {clientRoutes} from './roles/client.routes';
import {userRoutes} from './roles/user.routes';

export const routeList = [
    {
        role: ERole.ADMIN,
        routes: adminRoutes
    },
    {
        role: ERole.CLIENT,
        routes: clientRoutes
    },
    {
        role: ERole.USER,
        routes: userRoutes
    }
]