import React from 'react'
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GlobalCard from '../../../../shared/components/GlobalCard'
import { formatDate } from '../../../../shared/functions/formatDate';
import { ILocation } from '../../../../shared/interfaces/ILocation'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { IUser } from '../../../../shared/interfaces/IUser';
import { ERole } from '../../../../shared/enums/ERole';

interface IProps {
    location: ILocation;
}

export default function CleaningCard({ location }: IProps) {
    const { t } = useTranslation();
    const { role } = useSelector((state: RootState) => state.auth.userData) as IUser;

    return (
        <GlobalCard className={'position-relative my-2'}>
            <>
                <h6 className="mb-0">  {t('general.location')}: </h6>
                <h6 className="text-primary"> {location?.name} </h6>


                <h6 className="mb-0">  {t('admin.createdAt')}: </h6>
                <h6 className="text-primary"> {formatDate(location?.createdAt)} </h6>

                {role === ERole.ADMIN &&
                    !!location?._count?.cleanings && <Badge className={'bg-danger top-right-icon'}>
                        {location?._count?.cleanings}
                    </Badge>}
            </>
        </GlobalCard>
    )
}
