import _debounce from 'lodash/debounce';
import { useState } from 'react';
import { IPagination } from '../interfaces/IPagination';


interface IProps {
    perPage?: number;
    paginationKeys?: any;
}

export const usePagination = ({ perPage = 10, paginationKeys }: IProps) => {
    const [pagination, setPagination] = useState<IPagination | any>({
        page: 1,
        perPage: perPage,
        ...paginationKeys,
    });

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [count, setCount] = useState(0);

    const handlePages = (updatePage: number) => {
        setPagination({ ...pagination, page: updatePage });
        setPage(updatePage);
    };

    const changeFilterHandler = _debounce((name: string, value: string | null | number | boolean) => {
        setPagination((prev: IPagination) => ({ ...prev, page: 1, [name]: value }));
    }, 300);

    return {
        pagination,
        page,
        totalPages,
        setTotalPages,
        setPagination,
        handlePages,
        changeFilterHandler,
        count,
        setCount,
    };
};