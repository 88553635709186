//

import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IProps {
    dataLength: number;
    fetchData: () => void;
    hasMore?: boolean;
    showSeenAll?: boolean;
    children: JSX.Element;
}

const InfiniteLoader = ({dataLength, fetchData, hasMore = false, children, showSeenAll = false}: IProps) => {
    return (
        <InfiniteScroll
            dataLength={dataLength} //This is important field to render the next data
            next={fetchData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={showSeenAll &&
                <p style={{textAlign: 'center', marginTop: 10}}>
                    <b>You have seen it all!</b>
                </p>
            }
            // below props only if you need pull down functionality
        >
            {children}
        </InfiniteScroll>
    );
};

export default InfiniteLoader;
