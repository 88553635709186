import React, {SetStateAction, useState} from 'react';
import {Form, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {ERole} from '../../../../shared/enums/ERole';
import {handleError} from '../../../../shared/functions/handleError';
import {IUser} from '../../../../shared/interfaces/IUser';
import {UserService} from '../service';
import UserForm from './UserForm';

const UserCreate = () => {
    const [currentUser, setCurrentUser] = useState<Partial<IUser>>({
        role: ERole.USER
    });
    const {t} = useTranslation();
    const navigate = useNavigate();
    const submitHandler = () => {
        UserService.createUser(currentUser)
            .then(response => {
                if (response?.id) {
                    toast.success(t('general.successfullyCreated'))
                    navigate('/admin/user-list')
                }
            }).catch(err => handleError(err))
    }

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            submitHandler()
        }}>
            <Row className={'justify-content-center'}>
                <UserForm currentUser={currentUser as IUser}
                          setCurrentUser={setCurrentUser as React.Dispatch<SetStateAction<IUser>>}/>
            </Row>
        </Form>
    );
};
export default UserCreate;
